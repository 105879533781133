import { Component, Output, Input, EventEmitter, OnInit, OnDestroy, AfterViewInit, ElementRef} from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HostListener } from '@angular/core';
import { LocationStrategy } from '@angular/common';

import { ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatSelect} from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'create-alert',
  templateUrl: './create-alert.component.html',
  styleUrls: ['./create-alert.component.scss']
})
export class CreateAlertComponent implements OnInit, OnDestroy{

  @Output() endEvent = new EventEmitter();

  @Input() paciente_id: number;
  @Input() paciente_number: string;
  @Input() form:string;
  public fecha:Date;
  public user_email: string ="";

  public hasError:boolean=false;
  public querySubscription;


  constructor(private backendService : BackendService, private locationStrategy: LocationStrategy){
  }

  ngOnInit(){
    this.user_email=localStorage.getItem("email");
  }
  


  saveAlert(){
    let date_formatted = this.formatDatePicker(this.fecha);
    if(this.user_email!=null){
      this.hasError=false;
      var data = {"user_email": this.user_email, "patient_id":this.paciente_id, "form":this.form, "date":date_formatted};
      this.querySubscription = this.backendService.createAlert(data).subscribe((res)=>{
        if (res["errorCode"] > 0) {
          this.end();
        }
        else{
          alert("ERROR. Alert can not be created");
        }
        }, (err)=>{
          alert("ERROR. Alert can not be created");
        });
    }
    else{
      alert("ERROR. Alert can not be created");
    }
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
  }
  }

  end(){
    this.endEvent.emit("");
  }


  formatDatePicker(date){
    if(typeof date == 'string'){
        date = new Date(Date.parse(date));
        console.log(typeof date);
    }
    let day: string = date.getDate().toString();
    day = +day < 10 ? '0' + day : day;
    let month: string = (date.getMonth() + 1).toString();
    month = +month < 10 ? '0' + month : month;
    let year = date.getFullYear();
    return `${year}-${month}-${day}`;
}

}

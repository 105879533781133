import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreateAlertComponent } from './create-alert.component';
import { PageHeaderModule } from '../../shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [CommonModule,
        TranslateModule,
        PageHeaderModule,
        ReactiveFormsModule,
        FormsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        NgxMatSelectSearchModule,
        MatSelectModule,
        NgbModule],
        exports: [CreateAlertComponent],
    declarations: [CreateAlertComponent]
})
export class CreateAlertModule {}
